import "../../resources/less/Admin/Licences/LicenceSubNavigation.less";
import "../../resources/less/Admin/theme.less";
import "../../resources/less/Globals.less";
import "../../resources/less/components/Buttons/CommonButtons.less";
import "../../resources/less/Admin/components/Buttons.less";
import "../../resources/less/components/Popups/informationPagePopup.less";
import VideoTutorial from "../components/Help/VideoTutorial";

class AdminMain {
  constructor() {
    new VideoTutorial();
  }
}

$(window).on("load", () => {
  new AdminMain();
});
